import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Loading from '../App/Loading';
import Error from './Error';
import Form from './Form';

const Demo = ({ auth, coronaDemo, t }) => {
  if (!auth.UserGUID) {
    return null;
  }

  return (
    <div className="content">
      {coronaDemo.loading && <Loading />}
      {coronaDemo.error && <Error message={coronaDemo.error} />}
      {coronaDemo.formIsVisible && (
        <>
          <h4>{t('Your trial is a step away')}</h4>
          <br />
          <br />
          <Form />
        </>
      )}
      <br />
      <br />
    </div>
  );
};

export default connect((state) => ({
  auth: state.auth,
  coronaDemo: state.coronaDemo,
}))(withTranslation()(Demo));
