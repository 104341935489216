/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'redux-saga-first-router';
import { withTranslation } from 'react-i18next';

import Form from '../Shared/Form';
import { FREE_TRIAL_CLEAR_ROLE, FREE_TRIAL_SELECT, FREE_TRIAL_SUBMIT_DATA } from './constants';

const FreeTrialForm = ({ t, selected, onSelectOccupation, onFinish, ...props }) => {
  const onOccupationSelect = (occupation) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'UserAction',
        formName: 'TrialForm',
        formType: 'TrialForm',
        formStep: 'Step3',
        using_as: occupation,
      });
    }

    onSelectOccupation(occupation);
  };

  const onContinueFinish = (params) => {
    if (window.dataLayer) {
      const orderNumber = localStorage.getItem('orderNumber');
      // eslint-disable-next-line no-unused-vars
      const { product_of_interest, platform_of_interest, os, using_as, industry, ...otherSelected } = selected;
      window.dataLayer.push({
        event: 'UserAction',
        formName: 'TrialForm',
        formType: 'TrialForm',
        formStep: 'form_submitted',
        transaction_id: orderNumber,
        ...industry,
        ...otherSelected,
      });
    }
    onFinish(params);
  };

  return (
    <Form
      {...props}
      selected={selected}
      onOccupationSelect={onOccupationSelect}
      onContinueFinish={onContinueFinish}
      buttonLabel={t('Start your free trial')}
    />
  );
};

export default connect(
  (state) => ({
    selected: state.freeTrial.selected,
  }),
  (dispatch) => ({
    onFormChange: (data) => dispatch({ type: FREE_TRIAL_SELECT, data }),
    onSelectOccupation: (occupation) => dispatch({ type: FREE_TRIAL_SELECT, data: { using_as: occupation } }),
    onBack: (routingParams) => {
      dispatch(navigate('FREE_TRIAL', { ...routingParams, step: 'tool-of-choice' }));
      dispatch({ type: FREE_TRIAL_CLEAR_ROLE });
    },
    onFinish: (routingParams) => {
      dispatch(navigate('FREE_TRIAL', { ...routingParams, step: 'success' }));
      dispatch({ type: FREE_TRIAL_SUBMIT_DATA });
    },
  }),
)(withTranslation()(FreeTrialForm));
