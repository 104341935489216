import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Loading from '../App/Loading';
import { CustomStepper } from '../FreeTrial/Wiz';
import BetaForm from './betaForm';

const Beta = ({ loading, step, error, t }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <div className="maya-ple">
      <Helmet>
        <title>Enscape 4.1 Preview</title>
        <meta name="description" content="Enscape 4.1 Preview" />
      </Helmet>
      <div className="section-title-wrapper">
        <h3 className="section-title">{t('Enscape 4.1 Preview')}</h3>
      </div>
      <div className="content free-trials">
        <CustomStepper steps={Array(3).fill()} step={step} />
        <br />
        {error && <div className="error">{error.message}</div>}
        <br />
        <div className="content role-form">
          <BetaForm />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  loading: state.beta.loading,
  step: state.beta.step,
  error: state.beta.error,
}))(withTranslation()(Beta));
