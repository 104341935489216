import { CORONA_DEMO_ERROR, CORONA_DEMO_CLEAR, CORONA_DEMO_ORDER, CORONA_DEMO_SELECT } from './constants';

const initialState = {
  error: null,
  loading: false,
  formIsVisible: true,
  selected: {},
};

export default function coronaDemo(state = initialState, payload) {
  switch (payload.type) {
    case CORONA_DEMO_ERROR:
      return { ...state, error: payload.error, loading: false };
    case CORONA_DEMO_ORDER:
      return { ...state, loading: true, formIsVisible: false };
    case CORONA_DEMO_SELECT:
      return {
        ...state,
        selected: { ...state.selected, ...payload.data },
      };
    case CORONA_DEMO_CLEAR:
      return {
        ...state,
        selected: {},
      };
    default:
      return state;
  }
}
