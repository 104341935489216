export const BETA_SELECT = 'BETA_SELECT';
export const BETA_CLEAR = 'BETA_CLEAR';
export const BETA_CREATE = 'BETA_CREATE';
export const BETA_ERROR = 'BETA_ERROR';
export const BETA_SET_LOADING = 'BETA_SET_LOADING';
export const BETA_SET_STEP = 'BETA_SET_STEP';

export const BetaFields = [
  { label: 'Animation', value: 'Animation' },
  { label: 'Architecture', value: 'Architecture' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'Interior Design', value: 'Interior Design' },
  { label: 'Product Design', value: 'Product Design' },
  { label: 'VFX', value: 'VFX' },
  { label: 'Other', value: 'Other' },
];
