import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { BETA_SELECT } from './constants';
import { Industries, Roles } from '../FreeTrial/constants';
import { countries } from '../Utils/Countries';

const Individual = ({ selected, location, type, onFormChange, setCanContinue, t }) => {
  const { industry, dialCode, phoneNumber, phone_number, company } = selected;
  const { name, role, additional_name, additional_role } = industry || {};

  useEffect(() => {
    if (selected.industry && name && role) {
      if ((name === 'Other' && !additional_name) || (role === 'Other' && !additional_role)) {
        setCanContinue(false);
        return;
      }
      if (type === 'business' && !company) {
        setCanContinue(false);
        return;
      }
      setCanContinue(true);
      return;
    }

    setCanContinue(false);
  }, [selected]);

  useEffect(() => {
    const locationDialCode = countries.find((country) => country.code === location).dial_code;
    if (phone_number && phone_number.includes(locationDialCode)) {
      const phone = phone_number.replace(locationDialCode, '');
      onFormChange({ phoneNumber: phone });
    }
    onFormChange({ dialCode: locationDialCode });
  }, []);

  const countryDialCodes = countries.map(({ dial_code }) => dial_code);
  const uniqueCountryDialCodes = [...new Set(countryDialCodes)].sort();

  return (
    <>
      <p>{t('What is your field of practice?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select
            onChange={(e) =>
              onFormChange({
                industry: {
                  ...industry,
                  name: e.target.value,
                  additional_name: '',
                },
              })
            }
            value={name}
          >
            <option value="">{t('Please select')}</option>
            {Industries.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {name === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label htmlFor="number">{t('Fill in your industry')} *</label>
              <input
                type="text"
                required
                defaultValue={additional_name}
                onChange={(e) =>
                  onFormChange({
                    industry: {
                      ...industry,
                      additional_name: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <br />
        </>
      )}
      <p>{t('What is your role?')}</p>
      <div className="form-field-wrapper ">
        <div className="styled-select">
          <select
            onChange={(e) =>
              onFormChange({
                industry: {
                  ...industry,
                  role: e.target.value,
                  additional_role: '',
                },
              })
            }
            defaultValue={role}
          >
            <option value="">{t('Please select')}</option>
            {Roles.map(({ label, value }) => (
              <option key={value} value={value}>
                {t(label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      {role === 'Other' && (
        <>
          <div className="form-field-wrapper">
            <div className="item item-country">
              <label htmlFor="number">{t('Fill in your role')} *</label>
              <input
                type="text"
                required
                value={additional_role}
                onChange={(e) =>
                  onFormChange({
                    industry: {
                      ...industry,
                      additional_role: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <br />
        </>
      )}
      {type === 'business' && (
        <>
          <p>{t('Organization name')}</p>
          <div className="form-field-wrapper">
            <div className="item item-organization">
              <input
                type="text"
                required
                value={company || ''}
                onChange={(e) => onFormChange({ company: e.target.value })}
              />
            </div>
          </div>
          <br />
        </>
      )}
      <p>
        {t('Your phone number')} <span className="optional-label">{t('(optional)')}</span>
      </p>
      <div className="form-field-wrapper phone">
        <div className="styled-select">
          <select onChange={(e) => onFormChange({ dialCode: e.target.value })} value={dialCode}>
            <option value="" />
            {uniqueCountryDialCodes.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </select>
        </div>
        <div className="item item-organization">
          <input
            type="number"
            value={phoneNumber || ''}
            onChange={(e) => onFormChange({ phoneNumber: e.target.value })}
          />
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    selected: state.beta.selected,
    location: state.auth.Location,
  }),
  (dispatch) => ({
    onFormChange: (data) => dispatch({ type: BETA_SELECT, data }),
  }),
)(withTranslation()(Individual));
