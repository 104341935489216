import { put, call, select, cancel, takeLatest, delay, fork } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';
import api from '../Utils/Api';
import { CORONA_DEMO_ERROR, CORONA_DEMO_ORDER, CORONA_DEMO_PRODUCT_CODE, CORONA_DEMO_SUCCESS } from './constants';
import { placeOrder } from '../Shared/sagas';

export function* navigateCoronaDemo() {
  yield fork(watchDemoSuccess);
  yield fork(watchCreateOrder);

  const routing = yield select((state) => state.routing);
  checkAccessToken(routing.query || {});
  const isValidRequest = validateParams(routing.query || {});
  if (!isValidRequest) {
    yield put({ type: CORONA_DEMO_ERROR, error: 'generic' });
    yield cancel();
  }
}

export function* watchDemoSuccess() {
  yield takeLatest(CORONA_DEMO_SUCCESS, activateLicenseServer);
}

export function* watchCreateOrder() {
  yield takeLatest(CORONA_DEMO_ORDER, onCreateOrder);
}

export function humanizeError(message) {
  if (message.includes('exists') || message.includes('not eligible')) {
    return 'trial-exists';
  }
  if (message.includes('activation failed')) {
    return 'failed-activation';
  }

  return 'generic';
}

function* onCreateOrder() {
  const routing = yield select((state) => state.routing);
  const { selected } = yield select((state) => state.coronaDemo);
  const { phoneNumber, dialCode, ...otherSelected } = selected;

  function* successCallback() {
    yield put({ type: CORONA_DEMO_SUCCESS, accessToken: routing.query.access_token });
  }

  function* errorCallback(error) {
    yield put({ type: CORONA_DEMO_ERROR, error: error.message });
  }

  yield call(placeOrder, {
    additionalFields: {
      product_of_interest: 'Corona',
      code: CORONA_DEMO_PRODUCT_CODE,
      phone_number: phoneNumber ? `${dialCode || ''}${phoneNumber || ''}` : '',
      ...otherSelected,
      ...routing.query,
    },
    successCallback,
    errorCallback,
  });
}

function validateParams(params) {
  const required = ['platform', 'version', 'machine_id', 'signature'];
  return required.every((key) => Object.keys(params).includes(key));
}

function* activateLicenseServer({ accessToken }) {
  const maxRetries = 4; // 1 initial + 4 retries
  const retriesDelay = 2 * 1000;

  let retriesLeft = maxRetries;
  while (true) {
    try {
      yield call(api.fetch, `/activate?access_token=${accessToken}&noredirect=1`, {
        method: 'GET',
        host: 'http://localhost:30304',
        onUnauthorized: 'throw',
        on4xx: 'throw',
        on5xx: 'throw',
        mode: 'cors',
        parseJSON: false,
        credentials: 'omit',
        skipCSRFHeader: true,
        timeout: 10000,
      });

      yield put(navigate('CORONA_DEMO_SUCCESS'));
      break;
    } catch (error) {
      if (retriesLeft <= 0) {
        yield put({
          type: CORONA_DEMO_ERROR,
          error: `License server activation failed: ${error.message || 'reason unknown'}`,
        });

        yield cancel();
      }

      retriesLeft -= 1;
      yield delay(retriesDelay);
    }
  }
}

function checkAccessToken(params) {
  if (!params.access_token) {
    const url = new URL(window.location);
    url.hash = '';
    window.location = `${window.ACCOUNTS_URL}?return_to=${encodeURIComponent(url.href)}`;
  }
}
