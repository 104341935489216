import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Occupation from '../Shared/Form/Occupation';
import { BETA_CREATE, BETA_SELECT, BETA_SET_STEP } from './constants';

import Individual from './Individual';
import Student from './Student';
import Privacy from '../MayaPle/Privacy';

const getComponent = (occupation, props) => {
  switch (occupation) {
    case 'business':
      return <Individual type="business" {...props} />;
    case 'individual':
      return <Individual {...props} />;
    case 'student':
      return <Student {...props} />;
    default:
      return null;
  }
};

const BetaForm = ({ selected, onOccupationSelect, onSubmit, setStep, t }) => {
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    if (selected && selected.using_as) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [selected]);

  if (!selected || !selected.using_as) {
    const occupations = ['business', 'individual', 'student'];
    return (
      <>
        <Occupation occupations={occupations} onOccupationSelect={onOccupationSelect} />
        <br />
        <Privacy />
      </>
    );
  }

  return (
    <>
      {getComponent(selected.using_as, { setCanContinue })}
      <br />
      <br />
      <div className="centered">
        <button type="button" className="button" onClick={onSubmit} disabled={!canContinue}>
          {t('Submit')}
        </button>
      </div>
      <br />
      <div className="centered">
        <a className="back-button" onClick={() => onOccupationSelect(null)}>
          &lt; {t('Back')}
        </a>
      </div>
      <br />
      <Privacy />
    </>
  );
};

export default connect(
  (state) => ({
    selected: state.beta.selected,
  }),
  (dispatch) => ({
    onOccupationSelect: (occupation) => dispatch({ type: BETA_SELECT, data: { using_as: occupation } }),
    onSubmit: () => dispatch({ type: BETA_CREATE }),
    setStep: (step) => dispatch({ type: BETA_SET_STEP, step }),
  }),
)(withTranslation()(BetaForm));
