import { select, put, call, fork, spawn, takeLatest, cancel } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';
import api from '../Utils/Api';
import { getCookie } from '../Utils/cookiesHelper';
import {
  BUNDLE_TRIALS,
  FREE_TRIAL_CONFIRM,
  FREE_TRIAL_ERROR,
  FREE_TRIAL_LOADING,
  FREE_TRIAL_ORDER,
  FREE_TRIAL_RESEND,
  FREE_TRIAL_RESET,
  FREE_TRIAL_SELECT,
  FREE_TRIAL_SET_ORDER_PLACED,
  FREE_TRIAL_SET_STEP,
  FREE_TRIAL_SUBMIT_DATA,
  FREE_TRIAL_VERIFY,
  GET_STATUS_COMPLETE,
  PRODUCTS,
} from './constants';
import { placeOrder } from '../Shared/sagas';

const VERIFICATION_STATUS = { initial: 'initial', pending: 'pending', finished: 'finished' };

const urlToStepPoi = {
  'tool-of-choice': 1,
  role: 2,
  success: 3,
};

const urlToStepNoPoi = {
  role: 1,
  success: 2,
};

const urlToStep = (product) => {
  if (BUNDLE_TRIALS.includes(product)) {
    return urlToStepPoi;
  }
  return urlToStepNoPoi;
};

export function* freeTrial(callback) {
  const routing = yield select((state) => state.routing);
  if (window.location.hostname === window.domains.ple) {
    yield put(navigate('MAYA_PLE', { lang: routing.params.lang }));
  }

  if (window.location.hostname === window.domains.enscape && routing.params.product !== 'enscape-trial') {
    yield put(
      navigate('FREE_TRIAL', {
        step: 'tool-of-choice',
        ...routing.params,
        product: 'enscape-trial',
      }),
    );
  }

  if (routing.params.step !== 'success') {
    try {
      const orderPlaced = yield select((state) => state.freeTrial.orderPlaced);
      if (!orderPlaced) {
        const data = yield call(api.fetch, `/trial/eligible/${PRODUCTS[routing.params.product].code}`);
        yield put({ type: GET_STATUS_COMPLETE, data });

        // redirect to email confirm page
        if (data.is_eligible && (!routing.query || !Object.keys(routing.query).includes('isNewAccount'))) {
          window.location = `${window.ACCOUNTS_URL}/trial/email-confirm?return_to=${window.location.href}`;
          yield cancel();
        }

        const email = yield select((state) => state.auth.Email);
        const shouldCompleteForm = Boolean(localStorage.getItem(email)) || routing.query?.shouldContinue === 'true';
        // redirect to welcome back page if the user is not eligible and has completed the form
        if (!data.is_eligible && routing.params.step && !shouldCompleteForm) {
          yield put(
            navigate('FREE_TRIAL_WELCOME_BACK', { lang: routing.params.lang, product: routing.params.product }),
          );
        }

        // place an order if the user is eligible, should not verify their phone and on the correct pages
        const shouldVerifyPhone = data.sms_verification_required && data.verification_status !== 'finished';
        const isBundleTrial = BUNDLE_TRIALS.includes(routing.params.product);
        if (
          data.is_eligible &&
          !shouldVerifyPhone &&
          ((isBundleTrial && routing.params.step === 'tool-of-choice') ||
            (!isBundleTrial && routing.params.step === 'role'))
        ) {
          yield spawn(onPlaceOrder);
          // set the user's email in the local storage to indicate they have to fill out their form
          localStorage.setItem(email, 'shouldCompleteForm');
        }
      }
    } catch (error) {
      yield put({ type: FREE_TRIAL_ERROR, error });
    }
  } else {
    yield put({ type: GET_STATUS_COMPLETE, data: { is_eligible: true } });
  }
  if (callback instanceof Function) {
    yield call(callback);
  }
}

export function* navigateHome() {
  const routing = yield select((state) => state.routing);
  if (window.location.hostname === window.domains.ple) {
    yield put(navigate('MAYA_PLE', { lang: routing.params.lang }));
    return;
  }

  const defaultProduct = window.location.hostname === window.domains.enscape ? 'enscape-trial' : 'free-trial';
  yield put(
    navigate(
      'FREE_TRIAL',
      {
        ...routing.params,
        product: routing.params.product || defaultProduct,
        step: 'tool-of-choice',
      },
      { query: routing.query },
    ),
  );
}

const areRequiredFieldsFilled = (data, shouldSkipPlatform) => {
  const { product_of_interest, os, platform_of_interest, enscape_platform_of_interest } = data;
  if (shouldSkipPlatform) {
    return product_of_interest;
  }
  return (product_of_interest || os) && (platform_of_interest || enscape_platform_of_interest);
};

export function* navigateFreeTrial() {
  yield fork(watchPhoneVerify);
  yield fork(watchPhoneConfirm);
  yield fork(watchResetValidation);
  yield fork(watchResendValidation);
  yield fork(watchPlaceOrder);
  yield fork(watchSubmitUserData);

  const routing = yield select((state) => state.routing);
  const trial = yield select((state) => state.freeTrial);

  if (trial.sms_verification_required && trial.verification_status !== VERIFICATION_STATUS.finished) {
    let step = trial.verification_status === VERIFICATION_STATUS.pending ? 'verify' : 'phone';
    if (!window.localStorage.getItem('number')) {
      step = 'phone';
    }
    yield put(
      navigate(
        'FREE_TRIAL',
        {
          lang: routing.params.lang,
          product: routing.params.product,
          step,
        },
        { query: routing.query },
      ),
    );
    yield put({ type: FREE_TRIAL_SET_STEP, step: 0 });
  } else {
    const firstStep = trial.sms_verification_required ? 1 : 0;
    const stepOffset = trial.sms_verification_required ? 0 : 1;
    const step = urlToStep(routing.params.product)[routing.params.step] || firstStep;
    yield put({ type: FREE_TRIAL_SET_STEP, step: step - stepOffset });

    const skipToolProducts = ['chaos-vantage', 'chaos-player', 'chaos-cloud'];
    const shouldSkipPlatform = skipToolProducts.includes(routing.params.product);
    if (shouldSkipPlatform) {
      yield put({ type: FREE_TRIAL_SELECT, data: { product_of_interest: PRODUCTS[routing.params.product].name } });
      if (routing.params.step === 'tool-of-choice') {
        yield put(
          navigate(
            'FREE_TRIAL',
            {
              lang: routing.params.lang,
              product: routing.params.product,
              step: 'role',
            },
            { query: routing.query },
          ),
        );
      }
    }

    const newlySelected = yield select((state) => state.freeTrial.selected);
    if (
      !routing.params.step ||
      (routing.params.step === 'role' && !areRequiredFieldsFilled(newlySelected, shouldSkipPlatform))
    ) {
      yield put(
        navigate(
          'FREE_TRIAL',
          {
            lang: routing.params.lang,
            product: routing.params.product,
            step: 'tool-of-choice',
          },
          { query: routing.query },
        ),
      );
    }
  }
}

function* watchPhoneVerify() {
  yield takeLatest(FREE_TRIAL_VERIFY, phoneVerify);
}

function* phoneVerify({ code, number }) {
  const routing = yield select((state) => state.routing);
  try {
    yield put({ type: FREE_TRIAL_LOADING });
    yield call(api.fetch, '/trial/send_code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      on5xx: 'throw',
      parseJSON: false,
      body: JSON.stringify({ number: code + number, product_code: PRODUCTS[routing.params.product].code }),
    });
    window.localStorage.setItem('number', code + number);
    yield put(
      navigate(
        'FREE_TRIAL',
        {
          lang: routing.params.lang,
          product: routing.params.product,
          step: 'verify',
        },
        { query: routing.query },
      ),
    );
  } catch (error) {
    yield put({ type: FREE_TRIAL_ERROR, error });
    window.scrollTo(0, 0);
  }
}

function* watchPhoneConfirm() {
  yield takeLatest(FREE_TRIAL_CONFIRM, phoneConfirm);
}

function* phoneConfirm({ code }) {
  const number = window.localStorage.getItem('number');
  if (!number) {
    yield put(
      navigate(
        'FREE_TRIAL',
        { lang: routing.params.lang, product: routing.params.product, step: 'phone' },
        { query: routing.query },
      ),
    );
  }
  const routing = yield select((state) => state.routing);
  try {
    yield put({ type: FREE_TRIAL_LOADING });
    yield call(api.fetch, '/trial/validate_code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      on5xx: 'throw',
      parseJSON: false,
      body: JSON.stringify({ verification_code: code, number, product_code: PRODUCTS[routing.params.product].code }),
    });
    yield put(
      navigate(
        'FREE_TRIAL',
        {
          lang: routing.params.lang,
          product: routing.params.product,
          step: 'role',
        },
        { query: routing.query },
      ),
    );
  } catch (error) {
    yield put({ type: FREE_TRIAL_ERROR, error });
    window.scrollTo(0, 0);
  }
}

function* watchSubmitUserData() {
  yield takeLatest(FREE_TRIAL_SUBMIT_DATA, submitUserData);
}

function* submitUserData() {
  const { selected } = yield select((state) => state.freeTrial);
  const routing = yield select((state) => state.routing);
  const { phoneNumber, dialCode, ...otherSelected } = selected;
  const phone_number = phoneNumber ? `${dialCode}${phoneNumber}` : '';
  let body = {
    phone_number,
    ...otherSelected,
  };
  const subscriptionNumber = localStorage.getItem('subscriptionNumber');
  if (subscriptionNumber) {
    body = { ...body, subscription_number: subscriptionNumber, code: PRODUCTS[routing.params.product].code };
  }

  yield call(api.fetch, '/trial/info', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    on5xx: 'throw',
    parseJSON: false,
    body: JSON.stringify(body),
  });
}

function* watchResetValidation() {
  yield takeLatest(FREE_TRIAL_RESET, resetValidation);
}

function* resetValidation() {
  window.localStorage.removeItem('number');
  const routing = yield select((state) => state.routing);
  yield put(
    navigate(
      'FREE_TRIAL',
      {
        lang: routing.params.lang,
        product: routing.params.product,
        step: 'phone',
      },
      { query: routing.query },
    ),
  );
}

function* watchResendValidation() {
  yield takeLatest(FREE_TRIAL_RESEND, resendValidation);
}

function* resendValidation() {
  yield call(phoneVerify, { code: '', number: window.localStorage.getItem('number') });
}

function* watchPlaceOrder() {
  yield takeLatest(FREE_TRIAL_ORDER, onPlaceOrder);
}

function* sendUtmParams(subscription = {}) {
  const utmParams = getCookie('utm_campaign_params');
  if (utmParams) {
    yield call(api.fetch, '/trial/info', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      on5xx: 'throw',
      parseJSON: false,
      body: JSON.stringify({ ...JSON.parse(decodeURIComponent(utmParams)), ...subscription }),
    });
  }
}

function* onPlaceOrder() {
  const routing = yield select((state) => state.routing);

  const { isNewAccount } = routing.query || {};
  const isNewAccountBool = isNewAccount === 'true';

  function* successCallback(response) {
    yield put({ type: FREE_TRIAL_SET_ORDER_PLACED, isPlaced: true });
    // Save subscriptionID in localStorage for later use
    localStorage.setItem('subscriptionNumber', response.subscriptionNumber);
    localStorage.setItem('orderNumber', response.orderNumber);
    const subscription = {
      subscription_number: response.subscriptionNumber,
      code: PRODUCTS[routing.params.product].code,
    };
    yield call(sendUtmParams, subscription);
  }

  function* errorCallback(error) {
    yield put({ type: FREE_TRIAL_ERROR, error });
  }

  yield call(placeOrder, {
    additionalFields: {
      product_type: PRODUCTS[routing.params.product].formType,
      code: PRODUCTS[routing.params.product].code,
      is_newly_registered_account: isNewAccountBool,
    },
    successCallback,
    errorCallback,
  });
}
export function* navigateWelcomeBack() {
  const routing = yield select((state) => state.routing);
  const trial = yield select((state) => state.freeTrial);
  if (trial.is_eligible) {
    yield put(
      navigate(
        'FREE_TRIAL',
        {
          lang: routing.params.lang,
          product: routing.params.product,
          step: 'role',
        },
        { query: routing.query },
      ),
    );
  }
}

export const slugIsBeta = (slug) => {
  return slug.substring(0, 4) === 'beta';
};
