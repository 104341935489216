import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Loading from '../App/Loading';

import Wiz from './Wiz';
import Verify from './Verify';
import Tool from './Tool';
import Download from './Download';
import VerifyConfirm from './VerifyConfirm';
import Form from './Form';
import { BUNDLE_TRIALS } from './constants';
import ProductsStrip from './ProductsStrip';

const steps = (product) => {
  const s = [];

  if (BUNDLE_TRIALS.includes(product)) {
    s.push({
      Component: () => <Tool product={product} />,
    });
  }
  s.push({
    Component: () => <Form />,
  });
  s.push({
    Component: () => <Download />,
  });

  return s;
};

const verifyStep = (step) => {
  return {
    label: 'Verify your account.',
    Component: () => (step === 'phone' ? <Verify /> : <VerifyConfirm />),
  };
};

const getPageTitle = (step) => {
  switch (step) {
    case 'tool-of-choice':
      return 'Your trial download is just moments away.';
    case 'phone':
    case 'verify':
      return 'Verify your account.';
    case 'success':
      return "We're happy to have you onboard!";
    default:
      return 'Tell us a bit more about yourself.';
  }
};

const getPageSubitle = (step) => {
  switch (step) {
    case 'phone':
      return 'Please enter your phone number. We will send you a one-time verification code';
    case 'tool-of-choice':
      return "Let's customize your trial experience.";
    default:
      return '';
  }
};

const FreeTrial = ({ routing, error, progressBar, smsVerificationRequired, isEligible, t }) => {
  const availableSteps = smsVerificationRequired
    ? [verifyStep(routing.params.step), ...steps(routing.params.product)]
    : steps(routing.params.product);

  if (isEligible === null && routing.params.step !== 'success') {
    return null;
  }

  if (progressBar.step == null || progressBar.step < 0) {
    return <Loading />;
  }

  return (
    <div className="content free-trials">
      <h3>{t(getPageTitle(routing.params.step))}</h3>
      <div className="subtitle">{t(getPageSubitle(routing.params.step))}</div>
      {error && <div className="error">{error.message}</div>}
      <ProductsStrip product={routing.params.product} />
      <Wiz steps={availableSteps} progressBar={progressBar} />
    </div>
  );
};

export default connect((state) => ({
  routing: state.routing,
  error: state.freeTrial.error,
  progressBar: state.freeTrial.progressBar,
  smsVerificationRequired: state.freeTrial.sms_verification_required,
  isEligible: state.freeTrial.is_eligible,
}))(withTranslation()(FreeTrial));
