import { call, put, select, takeLatest, fork } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';

import Api from '../Utils/Api';
import { BETA_CREATE, BETA_ERROR, BETA_SET_LOADING, BETA_SET_STEP } from './constants';
import { PRODUCTS } from '../FreeTrial/constants';
import { placeOrder } from '../Shared/sagas';

export function* navigateBeta() {
  yield fork(watchCreate);

  yield put({ type: BETA_SET_LOADING, loading: true });

  const routing = yield select((state) => state.routing);
  let result = {};
  try {
    result = yield call(Api.fetch, `/trial/eligible/${PRODUCTS[routing.params.product].code}`);
  } catch (error) {
    yield put({ type: BETA_ERROR, error });
  }
  yield put({ type: BETA_SET_LOADING, loading: false });

  if (!result.is_eligible) {
    yield put(
      navigate('BETA_WELCOME_BACK', {
        lang: routing.params.lang,
        product: routing.params.product,
      }),
    );
  }
}

function* watchCreate() {
  yield takeLatest(BETA_CREATE, onCreate);
}

function* onCreate() {
  yield put({ type: BETA_SET_LOADING, loading: true });
  const routing = yield select((state) => state.routing);
  const { selected } = yield select((state) => state.beta);
  const { dialCode, phoneNumber, ...otherSelected } = selected;

  try {
    yield call(placeOrder, {
      additionalFields: {
        code: PRODUCTS[routing.params.product].code,
      },
      successCallback: () => {},
      errorCallback: () => {},
    });

    const bodyInfo = {
      phone_number: phoneNumber ? `${dialCode}${phoneNumber}` : '',
      ...otherSelected,
    };

    yield call(Api.fetch, '/trial/info', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      on5xx: 'throw',
      parseJSON: false,
      body: JSON.stringify(bodyInfo),
    });

    yield put(
      navigate('BETA_SUCCESS', {
        lang: routing.params.lang,
        product: routing.params.product,
      }),
    );
  } catch (error) {
    yield put({ type: BETA_ERROR, error });
    window.scrollTo(0, 0);
  }
  yield put({ type: BETA_SET_STEP, step: 2 });
  yield put({ type: BETA_SET_LOADING, loading: false });
}
