export const GET_STATUS_COMPLETE = 'GET_STATUS_COMPLETE';
export const FREE_TRIAL_LOADING = 'FREE_TRIAL_LOADING';
export const FREE_TRIAL_ERROR = 'FREE_TRIAL_ERROR';
export const FREE_TRIAL_CLEAR_ERROR = 'FREE_TRIAL_CLEAR_ERROR';
export const FREE_TRIAL_SET_STEP = 'FREE_TRIAL_SET_STEP';
export const FREE_TRIAL_SELECT = 'FREE_TRIAL_SELECT';
export const FREE_TRIAL_CLEAR_ROLE = 'FREE_TRIAL_CLEAR_ROLE';
export const FREE_TRIAL_CONFIRM = 'FREE_TRIAL_CONFIRM';
export const FREE_TRIAL_RESET = 'FREE_TRIAL_RESET';
export const FREE_TRIAL_RESEND = 'FREE_TRIAL_RESEND';
export const FREE_TRIAL_ORDER = 'FREE_TRIAL_ORDER';
export const FREE_TRIAL_SET_ORDER_PLACED = 'FREE_TRIAL_SET_ORDER_PLACED';
export const FREE_TRIAL_VERIFY = 'FREE_TRIAL_VERIFY';
export const FREE_TRIAL_SUBMIT_DATA = 'FREE_TRIAL_SUBMIT_DATA';
export const Roles = [
  {
    label: 'Procurement / Admin',
    value: 'Administrator',
  },
  {
    label: 'Architect',
    value: 'Architect',
  },
  {
    label: 'BIM Specialist',
    value: 'BIM Manager',
  },
  {
    label: 'Business Owner',
    value: 'Business Owner',
  },
  {
    label: 'Construction Specialist',
    value: 'Construction Specialist',
  },
  {
    label: 'Designer - Interior',
    value: 'Interior Designer',
  },
  {
    label: 'Designer - Product',
    value: 'Designer - Product',
  },
  {
    label: 'Director / Principal / Team Leader',
    value: 'Director',
  },
  {
    label: 'Educator / Professor / Teacher',
    value: 'Educator',
  },
  {
    label: 'Engineer',
    value: 'Engineer',
  },
  {
    label: 'Project Manager',
    value: 'Project Manager',
  },
  {
    label: 'Student',
    value: 'Student',
  },
  {
    label: 'Visualization Specialist / 3D Artist',
    value: '3D Artist',
  },
  {
    label: 'Technical Director',
    value: 'Technical Director',
  },
  {
    label: 'CG / VFX Supervisor',
    value: 'VFX Supervisor',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const Industries = [
  {
    label: 'Advertising',
    value: 'Advertising',
  },
  {
    label: 'Animation',
    value: 'Animation',
  },
  {
    label: 'Architecture',
    value: 'Architecture',
  },
  {
    label: 'Automotive',
    value: 'Automotive',
  },
  {
    label: 'Building Operations and Management',
    value: 'Building Operations and Management',
  },
  {
    label: 'Construction',
    value: 'Construction',
  },
  {
    label: 'Education / Academia',
    value: 'Education / Academia',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Gaming',
    value: 'Gaming',
  },
  {
    label: 'Interior Design',
    value: 'Interior Design',
  },
  {
    label: 'Landscape Architecture',
    value: 'Landscape Architecture',
  },
  {
    label: 'Product Design',
    value: 'Product Design',
  },
  {
    label: 'VFX',
    value: 'VFX',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const StudyFields = [
  { label: 'VFX', value: 'VFX' },
  { label: 'Animation', value: 'Animation' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'Architecture', value: 'Architecture' },
  { label: 'Interior Design', value: 'Interior Design' },
  { label: 'Product Design', value: 'Product Design' },
];

export const PRODUCTS = {
  'free-trial': { name: 'Free Trial', code: 'CS00MPMOTCL001', formType: 'chaos_trial' },
  'chaos-cloud': { name: 'Chaos Cloud', code: 'CL00NOMOTCC001', formType: 'chaos_cloud' },
  'chaos-player': { name: 'Chaos Player', code: 'CP00NOMOTCL001', formType: 'chaos_player' },
  'chaos-vantage': { name: 'Chaos Vantage', code: 'CV00NOMOTCL001', formType: 'chaos_vantage' },
  'enscape-trial': { name: 'Enscape Trial', code: 'EN00MPW2TCL001', formType: 'enscape_trial' },
  'beta-example-key': {
    name: 'V-Ray 6 for SketchUp, beta',
    code: 'VR06SKSPBCL001',
    forum: 'https://forums.chaos.com/forum/v-ray-6-for-sketchup-beta',
    poi: 'v-ray for sketchup beta',
    formType: 'beta',
  },
  'beta-vray-revit': {
    name: 'V-Ray 6 for Revit, beta',
    code: 'VR06RESPBCL001',
    forum: 'https://forums.chaos.com/forum/v-ray-6-for-revit-beta',
    poi: 'v-ray for revit beta',
    formType: 'beta',
  },
  enscape: { name: 'Enscape Beta', code: 'EN04MPSPLCL001', formType: 'beta' },
};

export const BUNDLE_TRIALS = ['free-trial', 'enscape-trial'];
