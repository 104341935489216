import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { PRODUCTS } from './constants';

// Adv product codes are needed because the @chaos/download-cards package
// does not support trial versions of the Standalone products
const ADV_PRODUCT_CODES = Object.freeze({
  // Chaos Player
  CP00NOMOTCL001: 'CP00NOMOECL001',
  // Chaos Vantage
  CV00NOMOTCL001: 'CV00NOSPSCL001',
  // Chaos Trial
  CS00MPMOTCL001: 'CS00MPMOTCL001',
  // Enscape Trial
  EN00MPW2TCL001: 'EN00MPMOTCL001',
});

const getButtonLink = (product) => {
  const trialProductCode = PRODUCTS[product].code;
  const advProductCode = ADV_PRODUCT_CODES[trialProductCode];
  switch (product) {
    case 'chaos-cloud':
      return 'https://cloud.chaos.com/how-to';
    case 'enscape-trial':
      return `${window.DOWNLOADS_URL}/products/${advProductCode}`;
    default: {
      return `${window.MY_CHAOS_URL}/download/${advProductCode}`;
    }
  }
};

const Download = ({ routing, email, t }) => {
  React.useEffect(() => {
    // remove the user's email from the local storage to indicate they've filled out the form
    localStorage.removeItem(email);
    localStorage.removeItem('subscriptionNumber');
    localStorage.removeItem('orderNumber');
  }, []);

  const isCloud = routing.params.product === 'chaos-cloud';
  const buttonText = isCloud ? 'Start rendering' : 'Download your trial';
  const buttonLink = getButtonLink(routing.params.product);
  const trialDuration = window.THEME === 'enscape' ? '14' : '30';

  return (
    <div className="content">
      <div className="download">
        <img src="/images/trial/success.svg" alt="trial" />
        <p>
          {t(`Now you can explore everything included in your free {{duration}}-day trial.`, {
            duration: trialDuration,
          })}
        </p>
        <br />
        <br />
        <button
          type="button"
          className="button"
          onClick={() => {
            window.location = buttonLink;
          }}
        >
          {t(buttonText)}
        </button>
      </div>
    </div>
  );
};

export default connect((state) => ({
  routing: state.routing,
  email: state.auth.Email,
}))(withTranslation()(Download));
