import React from 'react';

const ProductsStrip = ({ product }) => {
  switch (product) {
    case 'enscape-trial':
      return <Enscape />;
    case 'chaos-cloud':
      return <Cloud />;
    case 'chaos-player':
      return <Player />;
    case 'chaos-vantage':
      return <Vantage />;
    default:
      return <Chaos />;
  }
};

const Enscape = () => {
  return (
    <div className="trial-products-strip">
      <img src="/images/trial/products-strip/enscape-color.svg" alt="coroenscapena" />
      <img src="/images/trial/products-strip/phoenix.svg" alt="phoenix" />
      <img src="/images/trial/products-strip/vantage.svg" alt="vantage" />
      <img src="/images/trial/products-strip/player.svg" alt="player" />
      <img src="/images/trial/products-strip/cylindo.svg" alt="cylindo" />
      <img src="/images/trial/products-strip/cloud.svg" alt="cloud" />
      <img src="/images/trial/products-strip/vray.svg" alt="vray" />
      <img src="/images/trial/products-strip/cosmos.svg" alt="cosmos" />
      <img src="/images/trial/products-strip/scans.svg" alt="scans" />
      <img src="/images/trial/products-strip/corona.svg" alt="corona" />
    </div>
  );
};

const Chaos = () => {
  return (
    <div className="trial-products-strip">
      <img src="/images/trial/products-strip/vray-color.svg" alt="vray" />
      <img src="/images/trial/products-strip/cosmos.svg" alt="cosmos" />
      <img src="/images/trial/products-strip/scans.svg" alt="scans" />
      <img src="/images/trial/products-strip/corona.svg" alt="corona" />
      <img src="/images/trial/products-strip/enscape.svg" alt="enscape" />
      <img src="/images/trial/products-strip/phoenix.svg" alt="phoenix" />
      <img src="/images/trial/products-strip/vantage.svg" alt="vantage" />
      <img src="/images/trial/products-strip/player.svg" alt="player" />
      <img src="/images/trial/products-strip/cylindo.svg" alt="cylindo" />
      <img src="/images/trial/products-strip/cloud.svg" alt="cloud" />
    </div>
  );
};

const Cloud = () => {
  return (
    <div className="trial-products-strip">
      <img src="/images/trial/products-strip/corona-color.svg" alt="corona" />
      <img src="/images/trial/products-strip/enscape-color.svg" alt="enscape" />
      <img src="/images/trial/products-strip/phoenix-color.svg" alt="phoenix" />
      <img src="/images/trial/products-strip/vantage-color.svg" alt="vantage" />
      <img src="/images/trial/products-strip/player-color.svg" alt="player" />
      <img src="/images/trial/products-strip/cylindo-color.svg" alt="cylindo" />
      <img src="/images/trial/products-strip/cloud-color.svg" alt="cloud" />
      <img src="/images/trial/products-strip/vray-color.svg" alt="vray" />
      <img src="/images/trial/products-strip/cosmos-color.svg" alt="cosmos" />
      <img src="/images/trial/products-strip/scans-color.svg" alt="scans" />
    </div>
  );
};

const Player = () => {
  return (
    <div className="trial-products-strip">
      <img src="/images/trial/products-strip/player-color.svg" alt="player" />
      <img src="/images/trial/products-strip/vantage.svg" alt="vantage" />
      <img src="/images/trial/products-strip/cylindo.svg" alt="cylindo" />
      <img src="/images/trial/products-strip/cloud.svg" alt="cloud" />
      <img src="/images/trial/products-strip/vray.svg" alt="vray" />
      <img src="/images/trial/products-strip/cosmos.svg" alt="cosmos" />
      <img src="/images/trial/products-strip/scans.svg" alt="scans" />
      <img src="/images/trial/products-strip/corona.svg" alt="corona" />
      <img src="/images/trial/products-strip/enscape.svg" alt="enscape" />
      <img src="/images/trial/products-strip/phoenix.svg" alt="phoenix" />
    </div>
  );
};

const Vantage = () => {
  return (
    <div className="trial-products-strip">
      <img src="/images/trial/products-strip/vantage-color.svg" alt="vantage" />
      <img src="/images/trial/products-strip/player.svg" alt="player" />
      <img src="/images/trial/products-strip/cylindo.svg" alt="cylindo" />
      <img src="/images/trial/products-strip/cloud.svg" alt="cloud" />
      <img src="/images/trial/products-strip/vray.svg" alt="vray" />
      <img src="/images/trial/products-strip/cosmos.svg" alt="cosmos" />
      <img src="/images/trial/products-strip/scans.svg" alt="scans" />
      <img src="/images/trial/products-strip/corona.svg" alt="corona" />
      <img src="/images/trial/products-strip/enscape.svg" alt="enscape" />
      <img src="/images/trial/products-strip/phoenix.svg" alt="phoenix" />
    </div>
  );
};

export default ProductsStrip;
